import React from 'react';
import Layout from '../components/layout';

const TermsAndConditionsPage = () => {
  return (
    <Layout styles="terms-and-conditions" title="Terms and Conditions">
      Terms Here
    </Layout>
  );
};

export default TermsAndConditionsPage;
